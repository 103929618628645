import React, { useState, useEffect, useRef } from "react";
import {
  getSkills,
  addSkill,
  deleteSkill,
  updateSkill,
} from "../../../../api/apiCall.js";
import { HiMiniXMark } from "react-icons/hi2";
import { GoPlus } from "react-icons/go";
import { FaCheck } from "react-icons/fa6";

function SkillsExpertise() {
  const user = JSON.parse(localStorage.getItem('user'));
  const storedSkills = JSON.parse(localStorage.getItem('skills'));
  const token = localStorage.getItem('token');

  const [skills, setSkills] = useState(storedSkills)
  const [skillsArray, setSkillsArray] = useState(storedSkills)
  const inputRefs = useRef([]);
  const [loadId, setLoadId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAdded, setIsAdded] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isEditing, setIsEditing] = useState({ status:false, index:null });

  useEffect(() => {
    if (isAdded) {
      const timer = setTimeout(() => {
        setVisible(false);
        setIsAdded(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isAdded]);

  const handleClose = () => {
    setVisible(false);
    setIsAdded(false);
  };

  const handleGetSkills = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getSkills(data);
      console.log("Skills: ", response)
      if (response.status === 200) {
        setSkills(response.data);
        setSkillsArray(response.data);
        localStorage.setItem("skills", JSON.stringify(response.data));
      }
    }
    catch (error) {
      console.log("Error getting skills: ", error);
    }
    finally {
      setLoadId(null);
      setIsAdding(false);
      setIsUpdating(false);
      setIsEditing({ status:false, index:null });
    }
  };

  const handleAddSkill = async (index) => {
    setIsAdding(true);
    try {
      let data = {
        id: user.id,
        token: token,
        payloads: {
          name: skillsArray[index]
        }
      }

      const response = await addSkill(data);
      if (response.status === 201) {
        handleGetSkills();
        setIsAdded(true);
      }
    }
    catch (error) {
      console.log("Error adding skill: ", error);
      skillsArray.pop();
    }
    finally {
      setLoadId(null);
      setIsAdding(false);
      setIsUpdating(false);
      setIsEditing({ status:false, index:null });
    }
  }

  const handleSaveEdit = async (index) => {
      setIsUpdating(true);
      try {
        let data = {
          id: user.id,
          skillId: skills[index].id,
          token: token,
          payloads: {
            name: skillsArray[index]
          }
        }
  
        const response = await updateSkill(data);
        if (response.status === 200) {
          await handleGetSkills();
        }
      }
      catch (error) {
        console.log("Error updating skill: ", error);
      }
      finally {
        setLoadId(null);
        setIsAdding(false);
        setIsUpdating(false);
        setIsEditing({ status:false, index:null });
      }
    }

  const handleDeleteSkill = async (skillId) => {
    setLoadId(skillId);
    try {
      let data = {
        id: user.id,
        token: token,
        skillId: skillId
      }

      const response = await deleteSkill(data);
      if (response.status === 200) {
        handleGetSkills();
      }
    }
    catch (error) {
      console.log("Error deleteing language: ", error);
    }
  }

  const handleAddSkillArray = () => {
    setSkillsArray((prevSkillsArray) => [...prevSkillsArray, '']);
  };

  const handleChange = (e, index) => {
    const newKeywords = [...skillsArray];
    newKeywords[index] = e.target.value;
    setSkillsArray(newKeywords);
  };

  useEffect(() => {
    handleGetSkills();
  }, [])

  useEffect(() => {
      if(isEditing.status === false && isEditing.index === null){
        if (skillsArray.length > 0) {
          inputRefs.current[skillsArray.length - 1].focus();
        }
      }
      if(isEditing.status === true && isEditing.index !== null){
        inputRefs.current[isEditing.index].focus();
      }
      
    }, [skillsArray, isEditing]);

  return (
    <>
      <div className="flex flex-col max-md:px-3 pb-20 pt-6 max-md:pt-0 max-md:mt-8 max-md:max-w-full">
        <div className="flex justify-between gap-4 text-base tracking-tight max-md:flex-wrap">
          <div className="flex flex-col text-base tracking-tight max-md:flex-wrap">
            <div className="leading-[110%] text-base font-bold mb-2">
              Add Skills and Expertise
            </div>
            <p className="text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">Highlight your strengths! Add your skills and expertise to make your profile stand out!</p>
          </div>
        </div>
        <div className="flex gap-2 p-2.5 mt-6 text-sm leading-4 whitespace-nowrap rounded-lg border border-solid border-neutral-300 text-neutral-600 flex-wrap max-md:pr-5">
          <div className="flex gap-2 flex-wrap max-md:pr-5">
            {skillsArray.map((skill, index) => (
              <form key={index} className="relative" onSubmit={(e) => {
                e.preventDefault(); 
                if(isEditing.status){
                  handleSaveEdit(index)
                }
                else{
                  handleAddSkill(index)
                }
              }}>
                {skills.includes(skill) ? (
                  <input
                    type="text"
                    className={`px-4 py-2 rounded-lg ${
                      isEditing.status && isEditing.index === index
                        ? "bg-neutral-100"
                        : "bg-devRegBlue text-white"
                    }`}
                    value={skill.name}
                    readOnly={!isEditing.status || isEditing.index !== index}
                    disabled={isEditing.status && isEditing.index !== index}
                    onChange={(e) => {
                      setSkillsArray(
                        skillsArray.map((item, i) =>
                          i === isEditing.index ? { ...item, name: e.target.value } : item
                        )
                      );
                    }}
                    size={(skill.name || '').length || 1}
                    ref={(el) => (inputRefs.current[index] = el)}
                    onDoubleClick={() => setIsEditing({ status: true, index: index })}
                  />
                ) : (
                  <input
                    type="text"
                    className="justify-center px-4 py-2 rounded-lg bg-neutral-100"
                    value={skill.name}
                    onChange={(e) => handleChange(e, index)}
                    size={skill.length || 1}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                )}

                {isEditing.status && isEditing.index === index ? (
                  <button
                    type="submit"
                    className="absolute top-[5.5px] right-1 px-[2px] py-[2px] text-white bg-green-500 rounded-full"
                  >
                    {isUpdating ? (
                      <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                    ) : (
                      <FaCheck size={15}/>
                    )}
                  </button>
                ):
                  skills.includes(skill) ? (
                    <button
                      type="button"
                      className="absolute top-[5.5px] right-1 px-[2px] py-[2px] text-white bg-red-500 rounded-full"
                      onClick={() => handleDeleteSkill(skill.id)}
                    >
                      {loadId === skill.id ? (
                        <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                      ) : (
                        <HiMiniXMark size={15}/>
                      )}
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="absolute top-[5.5px] right-1 px-[2px] py-[2px] text-white bg-green-500 rounded-full"
                    >
                      {isAdding ? (
                        <div className="w-[15px] h-[15px] border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                      ) : (
                        <GoPlus size={15}/>
                      )}
                    </button>
                  )
                }
              </form>
            ))}
            <button
              className={`justify-center px-2.5 py-2 rounded-full border border-neutral-200 bg-neutral-100 bg-gray-400 text-black ${(isAdding || loadId !== null) ? "opacity-50 cursor-not-allowed" : ""
                }`}
              onClick={handleAddSkillArray}
              disabled={isAdding || loadId !== null || isEditing.status}
            >
              <GoPlus size={20}/>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SkillsExpertise;
