import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import logonav from "../assets/navbar-logo.svg";
const navLinks = [
  { text: "Why Register?", to: "/why-register" },
  { text: "Benefit Page", to: "/benefits" },
  { text: "About Profile", to: "/about-profile" },
  // { text: "Requirements", to: "/requirements" },
  { text: "About Us", to: "/about-us" },
  { text: "FAQ", to: "/faq" },
  { text: "Contact", to: "/contact" },
  // { text: "Forum", to: "/forum" },
];
function NavigationBar() {
  const [showNavLinks, setShowNavLinks] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const navigate = useNavigate();
  const toggleNavLinks = () => {
    setShowNavLinks(!showNavLinks);
  };
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <div className="border-b border-[#E5E7EB]">
      <header className="max-w-[1366px] m-auto flex  justify-between items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-6 py-6">
        <img
          loading="lazy"
          src={logonav}
          alt="Company logo"
          className="my-auto max-w-full w-[140px] max-sm:py-2 mr-20 max-md:mr-0 cursor-pointer max-sm:w-[120px] max-[378px]:w-[120px]"
          onClick={() => navigate("/")}
        />
        <nav className="max-md:flex-wrap flex flex-row max-sm:hidden max-[900px]:hidden cursor-pointer font-normal px-4">
          <>
            {navLinks.map((link, index) => (
              <div
                key={index}
                className={`cursor-pointer text-base max-lg:text-sm px-3 text-center max-2xl:text-md ${currentPage === link.text ? "text-devRegBlue" : ""
                  }`}
                onClick={() => {
                  navigate(link.to);
                  toggleNavLinks();
                  setCurrentPage(link.text);
                }}
              >
                {link.text}
              </div>
            ))}
          </>
        </nav>
        <div className="flex max-sm:gap-2 gap-4">
          <div className="max-[900px]:hidden flex max-sm:gap-2 gap-4">
            <div
              className=" my-auto text-lg text-devRegDarkBlue bg-[#E0E7FF] font-medium cursor-pointer  max-sm:text-[16px]  px-4 py-2 rounded-full max-[378px]:text-[14px]"
              onClick={handleLogin}
            >
              Login
            </div>

            <button
              className="text-white text-start bg-devRegDarkBlue max-sm:px-3 max-sm:py-1 max-sm:text-[16px] px-4 py-2 rounded-full font-medium max-[378px]:text-[14px] "
              onClick={() => navigate("/register")}
            >
              Register Now!
            </button>
          </div>
          <div className="self-stretch my-auto text-lg text-blue-900 font-bold min-[900px]:hidden ">
            {showNavLinks ? (
              <IoClose size={27} onClick={() => toggleNavLinks()} />
            ) : (
              <IoMenu size={27} onClick={() => toggleNavLinks()} />
            )}
          </div>
        </div>
      </header>
      {showNavLinks && (
        <div className="w-full bg-white  absolute z-[1000] py-4 transition-transform delay-150 ease-in min-[900px]:hidden">
          <nav
            className={`flex flex-col gap-4 cursor-pointer font-normal text-md pl-6`}
          >
            {navLinks.map((link, index) => (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => {
                  navigate(link.to);
                  toggleNavLinks();
                }}
              >
                {link.text}
              </div>
            ))}
            <div
              className=" my-auto text-lg w-[50%] ml-[-12px] text-devRegDarkBlue bg-[#E0E7FF] max-sm:px-3 max-sm:py-1 font-bold cursor-pointer px-4 py-2 rounded-full max-sm:text-[18px] max-[378px]:text-[16px]"
              onClick={() => navigate("/login")}
            >
              Login
            </div>

            <button
              className="text-white w-[50%] ml-[-12px] text-start bg-devRegDarkBlue max-sm:px-3 max-sm:py-1 max-sm:text-[18px] px-4 py-2 rounded-full font-semibold max-[378px]:text-[16px] "
              onClick={() => navigate("/register")}
            >
              Register Now!
            </button>
          </nav>
        </div>
      )}
    </div>
  );
}

export default NavigationBar;