import * as React from "react";
import { FaTimes, FaCheck } from "react-icons/fa";
import { useState, useEffect } from "react";
import { setIsLoading } from "../../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    submitGuardianInformation,
    uploadGuardianDocuments,
    deleteGuardianDocument,
    resetGuardianDocuments,
    compileGuradianDocuments,
} from "../../../api/apiCall";
import Modal from "react-modal";
import useUploadDocument from "../../../api/customHook/useUploadDocument";

const GuardianInformation = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    const userAddress = JSON.parse(localStorage.getItem('userAddress'));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [payloads, setPayloads] = useState({
        first_name: "",
        middle_name: "",
        last_name: "",
        sex: "",
        dob: "",
        place_of_birth: "",
        email: "ksdhfishvbsi@gmail.com",
        unit_number: "",
        street_name: "",
        city: userAddress.city || userAddress.town,
        state: userAddress.state,
        country: userAddress.country,
        postal_code: userAddress.postcode,
    })

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isDeletingId, setIsDeletingId] = useState(null);
    const {
        isModalOpen,
        isCheckboxChecked,
        setIsCheckboxChecked,
        isCheckboxChecked2,
        setIsCheckboxChecked2,
        openModal,
        closeModal,
    } = useUploadDocument();

    useEffect(() => {
        const resetData = async () => {
            let data = {
                id: user.id,
                token: token,
            }

            try {
                const response = await resetGuardianDocuments(data);
                if (response.status === 200) {
                    setUploadedFiles([]);
                }
            } catch (error) {
                console.error('Error resetting documents:', error);
            }
        };

        resetData();
    }, []);

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        setIsDragging(false);
        const files = Array.from(event.dataTransfer.files);
        const result = await handleUploadDocument(files[0]);
        if (result) {
            processFiles(files, result);
        }
    };

    const handleInputChange = async (event) => {
        const files = Array.from(event.target.files);
        const result = await handleUploadDocument(files[0]);
        if (result) {
            processFiles(files, result);
        }
    };

    const deleteFile = async (file_id) => {
        setIsDeletingId(file_id);
        try {
            let data = {
                id: user.id,
                token: token,
                documentId: file_id,
            }

            const response = await deleteGuardianDocument(data);
            if (response.status === 200) {
                setUploadedFiles(uploadedFiles.filter((file) => file.file_id !== file_id));
            }
        }
        catch (error) {
            console.log("Error deleting document: ", error);
        }
        finally {
            setIsDeletingId(null);
        }
    };

    const getFileIcon = (fileType) => {
        switch (fileType) {
            case 'image/jpeg':
            case 'image/png':
                return '/img.png';
            case 'application/pdf':
                return '/pdf.png';
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return '/doc.png';
            default:
                return '/file-icon.png';
        }
    };

    const truncateMessage = (message, maxLength = 20) => {
        return message.length > maxLength
            ? message.substring(0, maxLength) + "..."
            : message;
    };

    const formatFileSize = (size) => {
        if (size < 1024) return size + ' bytes';
        if (size < 1024 * 1024) return (size / 1024).toFixed(1) + ' KB';
        return (size / (1024 * 1024)).toFixed(1) + ' MB';
    };

    const processFiles = (files, result) => {
        const newFiles = files.map((file) => ({
            id: Date.now() + file.name,
            name: file.name,
            type: file.type.split('/')[1],
            file: file,
            file_id: result.id,
            file_category: result.type,
            size: formatFileSize(file.size)
        }));
        setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleUploadDocument = async (file) => {
        setIsUploading(true);
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    document: file,
                }
            }

            const response = await uploadGuardianDocuments(data);
            console.log("Uplaod document response: ", response.data);
            return response.data.data;
        }
        catch (error) {
            console.error("Error uploading document: ", error);
            return false;
        }
        finally {
            setIsUploading(false);
        }
    }

    const handleSubmitGuardianInformation = async () => {
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    first_name: payloads.first_name,
                    middle_name: payloads.middle_name,
                    last_name: payloads.last_name,
                    sex: payloads.sex,
                    dob: payloads.dob,
                    place_of_birth: payloads.birth_place,
                    email: payloads.email,
                    unit_number: payloads.unit_number,
                    street_name: payloads.street_name,
                    city: payloads.city,
                    state: payloads.state,
                    country: payloads.country,
                    postal_code: payloads.postal_code,
                }
            }

            const response = await submitGuardianInformation(data);
            if (response.status === 201) {
                return true;
            }
        }
        catch (error) {
            console.error("Error submitting guardian information: ", error);
        }
        finally {

        }
    }

    const handleCompileDocuments = async () => {
        try {
            let data = {
                id: user.id,
                token: token,
            }

            const response = await compileGuradianDocuments(data);
            if (response.status === 200) {
                return true;
            }
        }
        catch (error) {
            console.error("Error compiling documents: ", error);
            return false;
        }
        finally {

        }
    }

    const handleSubmit = async () => {
        dispatch(setIsLoading(true));
        const submitResposne = await handleSubmitGuardianInformation();
        const compileResponse = await handleCompileDocuments();
        if (submitResposne && compileResponse) {
            navigate("/upload-documents");
        }
        dispatch(setIsLoading(false));
    }

    return (
        <div className="flex overflow-hidden flex-col px-10 pb-[180px] max-md:px-5 max-md:pb-24 bg-[#F9FAFB]">
            <div className="flex flex-col self-center mt-16 w-full max-w-[700px] max-md:mt-10 bg-white rounded-2xl p-8 border border-[#E5E7EB]">
                <h2 className="pb-2.5 w-full text-2xl font-bold tracking-tight leading-tight text-blue-900">
                    Guardian Information
                </h2>
                <div className="p-4 mt-4 w-full text-sm tracking-tight bg-yellow-50 rounded-xl text-black text-opacity-80">
                    Content about the underage process
                </div>
                <form className="flex flex-col mt-4 w-full">
                    <div className="flex flex-wrap gap-4 justify-center items-start mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
                        <div className="flex-1 self-stretch">
                            <label htmlFor="first-name" className="block text-sm font-normal text-gray-700 mb-1">First Name</label>
                            <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                                <input
                                    type="text"
                                    id="firstname"
                                    placeholder="e.g. John"
                                    onChange={(e) => setPayloads({ ...payloads, first_name: e.target.value })}
                                    className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                    aria-label="Firstname"
                                />
                            </div>
                        </div>
                        <div className="flex-1 self-stretch">
                            <label htmlFor="middle-name" className="block text-sm font-normal text-gray-700 mb-1">Middle Name</label>
                            <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                                <input
                                    type="text"
                                    id="middlename"
                                    placeholder="e.g. Watson"
                                    onChange={(e) => setPayloads({ ...payloads, middle_name: e.target.value })}
                                    className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                    aria-label="Middlename"
                                />
                            </div>
                        </div>
                        <div className="flex-1 self-stretch">
                            <label htmlFor="last-name" className="block text-sm font-normal text-gray-700 mb-1">Last Name</label>
                            <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                                <input
                                    type="text"
                                    id="lastname"
                                    placeholder="e.g. Smith"
                                    onChange={(e) => setPayloads({ ...payloads, last_name: e.target.value })}
                                    className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                    aria-label="Lastname"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 justify-between items-start mt-4 w-full text-base leading-none text-stone-300 max-md:flex-col">
                        <div className="flex-1 self-stretch">
                            <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">Email</label>
                            <div className="w-full rounded-xl">
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="e.g. your_email@example.com"
                                    className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                    aria-label="Email Address"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 justify-center items-start mt-4 mb-4 w-full text-base leading-none text-stone-300 max-md:flex-col">
                        <div className="flex-1 self-stretch">
                            <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">Birth Date</label>
                            <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                                <input
                                    type="date"
                                    id="birthdate"
                                    placeholder="Birthdate"
                                    onChange={(e) => setPayloads({ ...payloads, dob: e.target.value })}
                                    className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                    aria-label="Birthdate"
                                />
                            </div>
                        </div>
                        <div className="flex-1 self-stretch">
                            <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">Birth Place</label>
                            <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                                <input
                                    type="text"
                                    id="birthplace"
                                    placeholder="Birthplace"
                                    onChange={(e) => setPayloads({ ...payloads, place_of_birth: e.target.value })}
                                    className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                    aria-label="Birthplace"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 self-stretch">
                        <label htmlFor="email" className="block text-sm font-normal text-gray-700 mb-1">Gender</label>
                        <div className="flex flex-wrap px-2 gap-4 justify-start items-start mb-6 mt-2 text-base leading-none text-stone-300">
                            <label
                                htmlFor="male"
                                className={`flex gap-2 self-stretch my-auto cursor-pointer ${payloads.sex === "Male" ? "text-black font-medium" : "text-gray-600"
                                    }`}
                                onClick={() => setPayloads({ ...payloads, sex: "Male" })}
                            >
                                <input
                                    type="radio"
                                    id="male"
                                    name="gender"
                                    value="male"
                                    checked={payloads.sex === "Male"}
                                    className="hidden"
                                    onChange={() => setPayloads({ ...payloads, sex: "Male" })}
                                    aria-label="Male"
                                />
                                <div
                                    className={`flex shrink-0 my-auto w-4 h-4 rounded-full ${payloads.sex === "Male"
                                        ? "bg-teal-400"
                                        : "bg-zinc-300"
                                        }`}
                                />
                                <div>Male</div>
                            </label>
                            <label
                                htmlFor="female"
                                className={`flex gap-2 self-stretch my-auto cursor-pointer ${payloads.sex === "Female" ? "text-black font-medium" : "text-gray-600"
                                    }`}
                                onClick={() => setPayloads({ ...payloads, sex: "Female" })}
                            >
                                <input
                                    type="radio"
                                    id="female"
                                    name="gender"
                                    value="female"
                                    checked={payloads.sex === "Female"}
                                    className="hidden"
                                    onChange={() => setPayloads({ ...payloads, sex: "Female" })}
                                    aria-label="Female"
                                />
                                <div
                                    className={`flex shrink-0 my-auto w-4 h-4 rounded-full ${payloads.sex === "Female"
                                        ? "bg-teal-400"
                                        : "bg-zinc-300"
                                        }`}
                                />
                                <div>Female</div>
                            </label>
                        </div>
                    </div>
                    <div className="w-full text-base leading-6 text-neutral-600 max-md:max-w-full font-[600]">
                        Address
                    </div>
                    <div className="flex flex-wrap gap-4 justify-center items-start mt-2 w-full text-base leading-none text-stone-300 max-md:flex-col">
                        <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                            <input
                                type="text"
                                id="unit"
                                placeholder="Unit #"
                                onChange={(e) => setPayloads({ ...payloads, unit_number: e.target.value })}
                                className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                aria-label="Unit #"
                            />
                        </div>
                        <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                            <input
                                type="text"
                                id="street"
                                placeholder="Street"
                                onChange={(e) => setPayloads({ ...payloads, street_name: e.target.value })}
                                className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                aria-label="Street"
                            />
                        </div>
                        <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                            <input
                                type="text"
                                id="city"
                                placeholder="City"
                                onChange={(e) => setPayloads({ ...payloads, city: e.target.value })}
                                className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                aria-label="City"
                            />
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 justify-center items-start mt-4 w-full text-base leading-none text-stone-300 max-md:flex-col">
                        <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                            <input
                                type="text"
                                id="state"
                                placeholder="State / Province"
                                onChange={(e) => setPayloads({ ...payloads, state: e.target.value })}
                                className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                aria-label="State / Province"
                            />
                        </div>
                        <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                            <input
                                type="text"
                                id="country"
                                placeholder="Country"
                                onChange={(e) => setPayloads({ ...payloads, country: e.target.value })}
                                className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                aria-label="Country"
                            />
                        </div>
                        <div className="flex-1 shrink gap-2.5 self-stretch rounded-xl">
                            <input
                                type="text"
                                id="postal"
                                placeholder="Postal Code"
                                onChange={(e) => setPayloads({ ...payloads, postal_code: e.target.value })}
                                className="w-full px-3 rounded-xl min-h-[45px] border border-solid border-neutral-300"
                                aria-label="Postal Code"
                            />
                        </div>
                    </div>
                </form>

                <div className="flex flex-col mt-6 w-full">
                    <div className="p-6 bg-[#e8ebf1] border border-[#556997] rounded-lg mb-4">
                        <div className="flex-1 shrink gap-4 w-full text-lg font-bold leading-none text-blue-900">
                            Uploaded Documents
                        </div>
                        <p className="mt-4 leading-6 text-neutral-600">
                            <span className="text-neutral-600">
                                Upload any two government IDs (Passport, Driver's License, or National ID), selfie, and a recent utility bill or statement with your address (no PO Boxes). For other valid documents, please
                            </span>{" "}
                            <span className="font-bold text-[#0082BA]">Contact Admin.</span>
                        </p>
                    </div>
                    <span className="text-neutral-400 text-sm">Note: (India) Bharat Drivers License are not accepted</span>
                    {isUploading ? (
                        <>
                            <div className="flex flex-col sm:flex-row gap-4 justify-center py-10 items-start mt-2 w-full leading-5 text-center text-stone-300 bg-gray-200 rounded">
                                <div className="inset-0 flex flex-col items-center justify-center z-10">
                                    <div className="relative flex justify-center items-center">
                                        <div className="h-10 w-10 rounded-full border-t-2 border-b-2 border-gray-200"></div>
                                        <div className="absolute top-0 left-0 h-10 w-10 rounded-full border-t-2 border-b-2 border-devRegBlue animate-spin"></div>
                                    </div>
                                    <p className="mt-4 text-sm max-sm:text-sm font-semibold text-devRegBlue animate-pulse">
                                        Validating...
                                    </p>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={`flex flex-col sm:flex-row gap-4 justify-center items-start mt-2 w-full leading-5 text-center text-stone-300 ${isDragging ? "bg-gray-200" : ""}`}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <label
                                    htmlFor="file-upload"
                                    className={`flex-1 shrink gap-2.5 self-stretch px-2.5 py-14 w-full rounded-2xl border border-solid border-neutral-300 min-h-[147px] min-w-[240px] cursor-pointer ${isDragging ? "bg-gray-200" : ""
                                        }`}
                                >
                                    Drag or Upload your documents here <br /> (jpg, png, pdf, word)
                                </label>
                                <input
                                    type="file"
                                    id="file-upload"
                                    multiple
                                    onChange={handleInputChange}
                                    className="hidden"
                                />
                            </div>
                        </>
                    )}

                    <div className="flex flex-wrap gap-4 mt-4">
                        {uploadedFiles.map((file) => (
                            <div
                                key={file.id}
                                className="flex items-center justify-between p-2 border rounded-md mb-2"
                            >
                                <div className="flex items-center">
                                    <img
                                        src={getFileIcon(file.file.type)}
                                        alt={file.name}
                                        className="w-8 h-8 mr-4"
                                    />
                                    <div className="flex flex-col">
                                        <p className="text-sm font-medium">
                                            {truncateMessage(file.name, 30)}
                                        </p>
                                        <p className="text-xs text-gray-500">{file.size}</p>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <FaCheck className="text-green-500 mr-2" />
                                    <button
                                        onClick={() => deleteFile(file.file_id)}
                                        className="text-red-500 hover:text-red-700"
                                        disabled={isDeletingId === file.file_id}
                                    >
                                        {isDeletingId === file.file_id ? (
                                            "Deleting..."
                                        ) : (
                                            <FaTimes />
                                        )}
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="flex mt-4 items-center">
                    <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={isCheckboxChecked}
                        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                    />
                    <div className="flex-1 ml-2">
                        I have read the accepted all{" "}
                        <span
                            className="text-[#0082BA] cursor-pointer"
                            onClick={() => {
                                openModal();
                            }}
                        >
                            Terms & Condition and Honesty and Transparency
                        </span>{" "}
                    </div>
                </div>

                <div className="flex justify-end mt-10">
                    <button
                        className="w-full px-10 sm:px-20 py-3 bg-blue-900 rounded-[30px] text-base font-normal text-white whitespace-nowrap"
                        onClick={handleSubmit}
                    >
                        Next
                    </button>
                </div>

                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                        className="modal max-w-[700px] m-auto h-full flex flex-col justify-center items-center outline-none"
                        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                    >
                        <>
                            <div className="flex flex-col px-9 py-9 mx-auto bg-white rounded-2xl shadow-lg max-w-[599px] max-md:px-5">
                                <div className="flex justify-between items-center">
                                    <div className="text-lg font-bold tracking-tight leading-7 text-blue-900 max-md:max-w-full">
                                        Terms & Condition
                                    </div>
                                    <button
                                        onClick={closeModal}
                                        className="text-gray-400 hover:text-gray-600 p-[1px] border border-gray-300 rounded-md"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={2}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>

                                <div className="flex flex-col p-4 mt-5 rounded-2xl border border-solid border-zinc-200 max-md:max-w-full">
                                    <div className="text-sm font-bold tracking-tight leading-5 text-black text-opacity-80 max-md:max-w-full">
                                        Terms & Condition
                                    </div>
                                    <div className="mt-2 text-sm font-light tracking-tight leading-4 text-neutral-600 max-md:max-w-full max-h-[200px] overflow-auto">
                                        Integer vitae nisl sit amet odio elementum ultrices in eget
                                        sem. Nunc consectetur pellentesque metus mattis venenatis.
                                        Integer vitae nisl sit amet odio elementum ultrices in eget
                                        sem. Nunc consectetur pellentesque metus mattis venenatis.
                                        Integer vitae nisl sit amet odio elementum ultrices in eget
                                        sem. Nunc consectetur pellentesque metus mattis venenatis.
                                        Integer vitae nisl sit amet odio elementum ultrices in eget
                                        sem. Nunc consectetur pellentesque metus mattis venenatis.
                                        Integer vitae nisl sit amet odio elementum ultrices in eget
                                        sem. Nunc consectetur pellentesque metus mattis venenatis.
                                        Integer vitae nisl sit amet odio elementum ultrices in eget
                                        sem. Nunc consectetur pellentesque metus mattis
                                        venenatis.Integer vitae nisl sit amet odio elementum
                                        ultrices in eget sem. Nunc consectetur pellentesque metus
                                        mattis venenatis. Integer vitae nisl sit amet
                                    </div>
                                </div>
                                <div className="flex items-center gap-2 mt-4">
                                    <input
                                        type="checkbox"
                                        checked={isCheckboxChecked2}
                                        className="shrink-0 w-4 h-4 rounded-md bg-zinc-300 cursor-pointer"
                                        onChange={() => setIsCheckboxChecked2(!isCheckboxChecked2)}
                                    />
                                    <div className="text-sm">
                                        I have read the accepted all
                                        <span className="text-sky-600 cursor-pointer">
                                            {" "}
                                            Terms & Condition
                                        </span>
                                    </div>
                                </div>
                                <div className="flex gap-2 justify-center mt-6 max-md:flex-wrap">
                                    <button
                                        onClick={closeModal}
                                        className="flex-1 px-6 py-2 bg-blue-200 text-blue-900 rounded-[30px] cursor-pointer"
                                    >
                                        Decline
                                    </button>
                                    <button
                                        onClick={() => {
                                            closeModal();
                                            setIsCheckboxChecked(true);
                                        }}
                                        disabled={!isCheckboxChecked2}
                                        className={`${isCheckboxChecked2 ? "bg-blue-900 cursor-pointer" : "bg-blue-700 cursor-not-allowed"
                                            } flex-1 px-6 py-2 text-white rounded-[30px]`}
                                    >
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </>
                    </Modal>

                )}
            </div>
        </div>
    );
};

export default GuardianInformation;
