import React from "react";
import { HiOutlineUser, HiOutlineEnvelope } from "react-icons/hi2";
import ReCAPTCHA from "react-google-recaptcha";
import { IoMdSend } from "react-icons/io";

function ContactUs() {
  const [captchaVerified, setCaptchaVerified] = React.useState(false);

  // Callback when the CAPTCHA is verified
  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    } else {
      setCaptchaVerified(false);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (!captchaVerified) {
      alert("Please verify that you are not a robot!");
      return;
    }
    alert("Form submitted successfully!");
  };

  return (
    <div className="flex overflow-hidden flex-col justify-center py-20 bg-[#F9FAFB]">
      <div className="flex justify-center items-center px-32 py-16 max-md:px-5">
        <div className="flex overflow-hidden flex-wrap self-stretch my-auto rounded-2xl border border border-solid min-w-[240px] w-[1200px]">
          <div className="flex flex-col min-w-[240px] w-1/2 max-[768px]:w-full">
            <div className="flex relative flex-col w-full h-full pt-[520px] max-md:pt-24">
              <img
                loading="lazy"
                srcSet="/ContactBldg.png"
                className="object-cover absolute inset-0 size-full"
                alt="Company Cover"
              />
              <div className="flex absolute bottom-0 left-5 flex-col justify-center min-h-[107px] max-sm:mt-auto max-sm:w-full max-sm:min-h-[349px]">
                <div className="text-base font-bold text-gray-50">Office</div>
                <div className="mt-2 text-xs text-gray-100">
                  Ground Floor, 470 Street, Kilda Road
                  <br />
                  Melbourne VICTORIA 3004 Australia
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 shrink p-8 my-auto bg-white rounded-none shadow-xl basis-0 w-1/2 min-w-[240px] max-md:px-5 max-md:max-w-full">
            <div className="flex flex-col justify-center items-start w-full text-center max-md:max-w-full">
              <div className="text-4xl font-bold text-gray-800">Contact Us</div>
              <div className="self-stretch mt-2 w-full text-base text-left text-gray-500 max-md:max-w-full">
                Bringing Credibility, Integrity, and Protection to Software
                Developers and Clients Worldwide
              </div>
            </div>
            <div className="flex flex-col mt-16 w-full max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-wrap gap-6 items-start w-full max-md:max-w-full">
                <div className="relative flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                  <label className="text-xs font-medium text-gray-600">First Name</label>
                  <div className="flex items-center border border-gray-400 rounded-lg mt-1">
                    <HiOutlineUser size={20} className="absolute left-4 w-5 self-center absolute text-gray-500" />
                    <input
                      type="text"
                      placeholder="e.g. John"
                      className="pl-12 w-full rounded-lg border-0 py-3 text-base text-gray-400"
                    />
                  </div>
                </div>
                <div className="relative flex flex-col flex-1 shrink basis-0 min-w-[240px]">
                  <label className="text-xs font-medium text-gray-600">Last Name</label>
                  <div className="flex items-center border border-gray-400 rounded-lg mt-1">
                    <HiOutlineUser size={20} className="absolute left-4 w-5 self-center absolute text-gray-500" />
                    <input
                      type="text"
                      placeholder="e.g. Dowry"
                      className="pl-12 w-full rounded-lg border-0 py-3 text-base text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div className="relative flex flex-col mt-6 w-full min-h-[64px] max-md:max-w-full">
                <label className="text-xs font-medium text-gray-600">Email</label>
                <div className="flex items-center border border-gray-400 rounded-lg mt-1">
                  <HiOutlineEnvelope size={20} className="absolute left-4 w-5 self-center absolute text-gray-500" />
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="pl-12 w-full rounded-lg border-0 text-base py-3 text-gray-400"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-6 w-full min-h-[179px] max-md:max-w-full">
                <label className="text-xs font-medium text-gray-600">Additional Message</label>
                <textarea
                  placeholder="Type a message here..."
                  className="p-3 mt-1 w-full rounded-lg border h-[160px] border-gray-400 text-base text-gray-400"
                />
              </div>
              <div className="flex gap-4 justify-between items-center mt-8 w-full max-md:flex-col">
                <ReCAPTCHA
                  sitekey="6Lek_6AqAAAAAGeITR4HdBnrocqB3fjZUdR0Q0eb"
                  onChange={handleCaptchaChange}
                />
                <button onClick={handleSubmit}
                  disabled={!captchaVerified}
                  className={`flex gap-1 justify-center items-center self-stretch py-2.5 pr-4 pl-5 my-auto w-40 text-base font-medium whitespace-nowrap ${captchaVerified ? "text-gray-50 bg-blue-900 cursor-pointer" : "bg-gray-300 cursor-not-allowed text-white"} rounded-lg max-md:w-full`}>
                  <div className="self-stretch my-auto">Send</div>
                  <IoMdSend />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;