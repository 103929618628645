import React, { useState, useEffect } from "react";
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import profileResgiteredIcon from "../assets/Profile/profileRegisteredIcon.svg";
import trophyProfile from "../assets/Profile/trophyProfile.png";
import ProfileSideBar from "../components/ProfileSideBar";
import {
    getProfilePreview,
} from "../../../../api/apiCall.js";
import { FaChevronLeft } from "react-icons/fa6";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { SlLocationPin } from "react-icons/sl";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { ImCoinDollar } from "react-icons/im";
import { SiInstagram, SiFacebook, SiLinkedin, SiYoutube, SiDiscord, SiTiktok } from "react-icons/si";
import { AiOutlineClockCircle } from "react-icons/ai";
import { HiOutlineBriefcase } from "react-icons/hi2";

function ProfileMain() {
    const [isOpen, setIsOpen] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const visitedId = searchParams.get('id');
    const [selectedTab, setSelectedTab] = useState("overview");
    const [loading, setLoading] = useState(false);
    const [profileInfo, setProfileInfo] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        handleGetProfileInfo();
    }, [])

    const handleGetProfileInfo = async () => {
        try {
            let data = {
                id: user.id,
                token: token,
                payloads: {
                    visited_id: visitedId ?? null
                }
            }

            const response = await getProfilePreview(data);
            if (response.status === 200) {
                setProfileInfo(response.data);
            }
        }
        catch (error) {
            console.log("Error fetching profile info: ", error);
        }
        finally {
            setLoading(false);
        }
    }

    function formatDate(date) {
        return moment(date).format('MMM YYYY');
    }

    function formatYear(date) {
        return moment(date).format('YYYY');
    }

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleClickBack = () => {
        navigate(-1);
    };

    return (
        <>
            <div className="flex items-center mt-9 mb-2 justify-start max-md:mt-2">
                <div className="flex gap-2 text-xl cursor-pointer text-blue-900" onClick={handleClickBack}>
                    <FaChevronLeft className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square cursor-pointer" />
                    Return
                </div>
            </div>
            {loading ? (
                <div className="flex flex-col mt-8 max-md:max-w-full min-h-screen">
                    <div className="flex items-center self-center mb-2 justify-between gap-3 bg-yellow-300 border border-gray-300 rounded-lg py-2 px-6 shadow-sm max-w-sm">
                        <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        <div className="text-white font-semibold">Loading..</div>
                    </div>
                </div>
            ) : (
                <div className="flex overflow-hidden flex-col pt-7 bg-white min-h-screen">
                    <div className="flex flex-col self-center mt-0 w-full max-w-full max-md:mt-0 max-md:max-w-full">
                        {profileInfo.profile_banner_path ? (
                            <img
                                alt=""
                                loading="lazy"
                                srcSet={profileInfo.profile_banner_path}
                                className="m-auto justify-center items-center w-full max-h-[200px] object-cover text-2xl text-center text-white bg-[#20396B] max-w-[1346px] max-md:max-w-full"
                            />
                        ) : (
                            <>
                                <div className="m-auto justify-center items-center px-16 pt-24 pb-20 w-full text-2xl text-center text-white bg-[#20396B] max-w-[1346px] max-md:px-5 max-md:max-w-full max-sm:text-base">
                                    Profile Cover / Ad Space (1000px x 200px)
                                </div>
                                <div className=""></div>
                            </>
                        )}
                        <div className="flex flex-col justify-center py-6 w-full max-md:max-w-full">
                            <div className="flex justify-between items-start px-4 w-full bg-white rounded-3xl max-md:max-w-full">
                                <div className="flex flex-wrap flex-1 shrink justify-between items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
                                    <div className="flex flex-wrap gap-4 items-start min-w-[240px] max-md:max-w-full">
                                        <img
                                            loading="lazy"
                                            alt=""
                                            srcSet={profileInfo.profile_photo_path}
                                            className="object-contain shrink-0 rounded-full aspect-square shadow-[0px_1px_10px_rgba(0,123,255,0.15)] w-[150px]"
                                        />
                                        <div className="flex flex-col min-w-[240px] w-[482px] max-md:max-w-full">
                                            <div className="flex flex-col w-full max-md:max-w-full">
                                                <div className="gap-4 self-stretch w-full text-3xl font-semibold leading-none text-neutral-800 max-md:max-w-full">
                                                    {profileInfo.fullname}
                                                </div>
                                                <div className="flex gap-2 items-center self-start mt-3">
                                                    <div className="self-stretch my-auto text-xl font-medium text-neutral-600 max-sm:text-lg">
                                                        {profileInfo.profession}
                                                    </div>
                                                    <div className="flex gap-0.5 items-center self-stretch px-2 py-1 my-auto text-sm leading-none text-sky-600 bg-sky-100 rounded-2xl">
                                                        <IoMdCheckmarkCircleOutline />
                                                        <div className="self-stretch my-auto">
                                                            Available to Work
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-start mt-3 w-full text-base text-neutral-600 max-md:max-w-full">
                                                <div className="flex gap-4 items-start self-stretch w-full max-md:max-w-full">
                                                    <div className="flex gap-2 justify-center items-center min-w-[240px]">
                                                        <SlLocationPin size={23} className="text-sky-600" />
                                                        <div className="self-stretch my-auto">
                                                            {`${profileInfo.city ? profileInfo.city + "," : ""} ${profileInfo.state ? profileInfo.state + "," : ""} ${profileInfo.country ? profileInfo.country : ""}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                {profileInfo.languages && (
                                                    <div className="flex gap-2 items-center mt-3">
                                                        <HiOutlineChatBubbleLeftRight size={23} className="text-sky-600" />
                                                        <div className="self-stretch my-auto">
                                                            I speak {profileInfo.languages?.map((language, index) => (
                                                                <>
                                                                    {language.language}{index + 1 === profileInfo.languages.length ? "" : ", "}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}

                                                {profileInfo.salary_rate && (
                                                    <div className="flex gap-2 items-center mt-3 whitespace-nowrap">
                                                        <ImCoinDollar size={23} className="text-sky-600" />
                                                        <div className="self-stretch my-auto">USD</div>
                                                        <div className="self-stretch my-auto">${profileInfo.salary_rate}/hr</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1 shrink items-end max-[768px]:items-start basis-0 min-w-[240px] max-md:max-w-full max-sm:items-start max-sm:mx-auto">
                                        <div className="flex flex-col items-end max-[768px]:items-start w-36 max-w-full text-xl font-semibold leading-loose text-sky-600 whitespace-nowrap max-sm:flex max-sm:flex-col max-sm:justify-between max-sm:pt-2.5 max-sm:w-full">
                                            <img
                                                loading="lazy"
                                                alt=""
                                                srcSet={profileResgiteredIcon}
                                                className="object-contain w-full aspect-[1.55] max-sm:w-6/12"
                                            />
                                            <div className="mt-2.5 max-sm:mr-auto">{profileInfo.serial_number}</div>
                                        </div>
                                        <div className="flex gap-4 items-start mt-6">
                                            <div className="flex gap-4 justify-center items-center max-sm:flex max-sm:flex-row max-sm:justify-start max-sm:mr-auto max-sm:w-full max-sm:text-left">
                                                <SiInstagram size={25} className="text-sky-600" />
                                                <SiFacebook size={25} className="text-sky-600" />
                                                <SiLinkedin size={25} className="text-sky-600" />
                                                <SiYoutube size={25} className="text-sky-600" />
                                                <SiDiscord size={25} className="text-sky-600" />
                                                <SiTiktok size={25} className="text-sky-600" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex max-[1024px]:flex-col justify-between max-[768px]:justify-between max-[768px]:text-lg max-[768px]:gap-2 max-[768px]:w-full items-start text-xl leading-loose border-b max-[425px]:pb-6 w-full max-md:max-w-full">
                            <div className="min-h-[47px] text-neutral-700 w-[900px] max-[1024px]:w-full max-sm:flex max-sm:flex-col max-sm:gap-2.5">
                                <div className="flex flex-wrap max-[425px]:flex-col gap-8 justify-between max-[768px]:justify-between max-[768px]:text-lg max-[768px]:gap-2 max-[768px]:w-full items-start px-2 text-xl leading-loose border-b max-[425px]:pb-6 border-neutral-400">
                                    <button className="gap-4 self-stretch py-2 pr-2 font-bold text-blue-900 whitespace-nowrap" onClick={() => setSelectedTab("overview")}>
                                        Overview
                                    </button>
                                    <button className="gap-4 self-stretch py-2 pr-2 whitespace-nowrap" onClick={() => setSelectedTab("features")}>
                                        Features
                                    </button>
                                    <button className="gap-4 self-stretch py-2 pr-2" onClick={() => setSelectedTab("work-history")}>
                                        Work History
                                    </button>
                                    <button className="gap-4 self-stretch py-2 pr-2" onClick={() => setSelectedTab("skills-experties")}>
                                        Skill & Expertise
                                    </button>
                                    <button className="gap-4 self-stretch py-2 pr-2 whitespace-nowrap" onClick={() => setSelectedTab("education")}>
                                        Education
                                    </button>
                                    <button className="gap-4 self-stretch py-2 pr-2 whitespace-nowrap" onClick={() => setSelectedTab("awards")}>
                                        Awards
                                    </button>
                                </div>
                                <div className="flex flex-row gap-16 justify-between mt-6 w-full max-md:max-w-full items-start max-[768px]:w-full max-[768px]:flex-col">
                                    <div className="flex flex-col min-w-[240px] w-[851px] max-[1024px]:w-full justify-center">
                                        {selectedTab === "overview" && (
                                            <div className="max-[1024px]:w-full flex flex-col max-[425px]:items-start items-center justify-center p-4 w-full max-md:max-w-full">
                                                <div className="flex flex-wrap max-[425px]:flex-col max-[425px]:items-start gap-2 justify-between items-center w-full max-md:max-w-full">
                                                    <div className="flex gap-2 items-center">
                                                        <img
                                                            loading="lazy"
                                                            alt=""
                                                            src={trophyProfile}
                                                            className="object-contain shrink-0 self-stretch my-auto w-9 aspect-square"
                                                        />
                                                        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0">
                                                            <div className="flex-1 shrink gap-2.5 w-full text-xl max-[1024px]:text-lg font-bold leading-loose text-neutral-600">
                                                                {profileInfo.year_of_experience} Years
                                                            </div>
                                                            <div className="text-base max-[1024px]:text-sm leading-none text-neutral-400">
                                                                Work Experienced
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <img
                                                            loading="lazy"
                                                            alt=""
                                                            src={trophyProfile}
                                                            className="object-contain shrink-0 self-stretch my-auto w-9 aspect-square"
                                                        />
                                                        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0">
                                                            <div className="flex-1 shrink gap-2.5 w-full text-xl max-[1024px]:text-lg font-bold leading-loose text-neutral-600">
                                                                {profileInfo.projects_completed} Projects
                                                            </div>
                                                            <div className="text-base max-[1024px]:text-sm leading-none text-neutral-400">
                                                                Completed
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <img
                                                            loading="lazy"
                                                            alt=""
                                                            src={trophyProfile}
                                                            className="object-contain shrink-0 self-stretch my-auto w-9 aspect-square"
                                                        />
                                                        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0">
                                                            <div className="flex-1 shrink gap-2.5 w-full text-xl max-[1024px]:text-lg font-bold leading-loose text-neutral-600">
                                                                {profileInfo.awards?.length} Awards
                                                            </div>
                                                            <div className="text-base max-[1024px]:text-sm leading-none text-neutral-400">
                                                                Achievements
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex gap-2 items-center">
                                                        <img
                                                            loading="lazy"
                                                            alt=""
                                                            src={trophyProfile}
                                                            className="object-contain shrink-0 self-stretch my-auto w-9 aspect-square"
                                                        />
                                                        <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0">
                                                            <div className="flex-1 shrink gap-2.5 w-full text-xl max-[1024px]:text-lg font-bold leading-loose text-neutral-600">
                                                                {profileInfo.educations?.length} Certificates
                                                            </div>
                                                            <div className="text-base max-[1024px]:text-sm leading-none text-neutral-400">
                                                                Accomplishment
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                        {/* Features */}
                                        {(selectedTab === "features" || selectedTab === "overview") && (
                                            <div className="flex flex-col p-4 w-full bg-white rounded-xl">
                                                <div className="text-3xl font-semibold leading-none text-neutral-800 max-md:max-w-full">
                                                    Features
                                                </div>
                                                <div className="flex flex-col py-4 w-full text-base rounded-md min-w-[320px] max-md:max-w-full">
                                                    {profileInfo.features?.map((feature, index) => (
                                                        <div className="mt-4" key={index}>
                                                            <img
                                                                loading="lazy"
                                                                alt=""
                                                                srcSet={feature.banner}
                                                                className="object-contain w-full max-md:max-w-full"
                                                            />
                                                            <div className="flex overflow-hidden flex-col px-2 mt-4 w-full max-md:max-w-full">
                                                                <div className="text-2xl font-semibold leading-loose text-neutral-800 max-md:max-w-full">
                                                                    Title: {feature.title}
                                                                </div>
                                                                <div className="mt-3 leading-6 text-neutral-800 max-md:max-w-full">
                                                                    {feature.content}
                                                                </div>
                                                                <div className="mt-3 leading-none text-neutral-500 max-md:max-w-full">
                                                                    url: {feature.link}
                                                                </div>
                                                                <div className="flex gap-2 items-start mt-3 w-full text-sm leading-none text-neutral-600 max-md:max-w-full">
                                                                    <div className="mt-1 text-base leading-none text-neutral-500 self-start">
                                                                        Keywords:
                                                                    </div>
                                                                    <div className="flex gap-2 flex-wrap">
                                                                        {feature.keywords?.split(',').map((keyword, index) => (
                                                                            <div key={index} className="gap-2.5 self-stretch px-4 py-2 rounded-2xl bg-neutral-100">
                                                                                {keyword}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}


                                        {/* About */}
                                        {selectedTab === "overview" && (
                                            <div className="flex flex-col p-4 w-full bg-white rounded-xl">
                                                <div className="text-3xl font-semibold leading-none text-neutral-800 max-md:max-w-full">
                                                    About {profileInfo.first_name}
                                                </div>
                                                <div className="mt-4 text-base leading-7 text-neutral-600 max-md:max-w-full">
                                                    {profileInfo.about}
                                                </div>
                                            </div>
                                        )}


                                        {/* Work History */}
                                        {(selectedTab === "work-history" || selectedTab === "overview") && (
                                            <div className="flex flex-col p-4 w-full bg-white rounded-xl">
                                                <div className="text-3xl font-semibold leading-none text-neutral-800 max-md:max-w-full">
                                                    Work History
                                                </div>
                                                <div className="flex flex-col justify-center mt-[-25px] py-4 w-full max-md:max-w-full">
                                                    <div className="flex flex-col flex-1 w-full max-md:max-w-full">
                                                        {profileInfo.workHistories?.map((workHistory, index) => (
                                                            <>
                                                                <div>
                                                                    <div className="flex flex-wrap gap-10 mt-7 justify-between items-center w-full max-md:max-w-full max-md:gap-5">
                                                                        <div className="flex flex-col items-start self-stretch my-auto min-w-[240px] w-[506px] max-md:max-w-full">
                                                                            <div className="flex gap-2 items-center">
                                                                                <img
                                                                                    loading="lazy"
                                                                                    alt=""
                                                                                    srcSet={workHistory.company_logo}
                                                                                    className="object-contain shrink-0 self-stretch my-auto aspect-square w-[55px]"
                                                                                />
                                                                                <div className="flex flex-col self-stretch my-auto min-w-[240px]">
                                                                                    <div className="flex gap-2 items-start">
                                                                                        <div className="text-lg font-bold leading-none text-blue-900">
                                                                                            {workHistory.position}
                                                                                        </div>
                                                                                        <div className="gap-2.5 self-stretch p-2 text-xs text-sky-600 border border-sky-600 border-solid bg-sky-600 bg-opacity-10 rounded-[100px]">
                                                                                            {workHistory.work_arrangement_other ? workHistory.work_arrangement_other : workHistory.work_arrangement}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mt-1 text-base leading-none text-neutral-600">
                                                                                        {workHistory.company_name} | {formatDate(workHistory.date_started)} - {workHistory.is_present ? 'Present' : formatDate(workHistory.date_ended)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div onClick={toggleOpen} className="cursor-pointer">
                                                                            {isOpen ? (
                                                                                <FaChevronUp size={25} className="object-contain text-neutral-500 shrink-0 self-stretch my-auto w-6 aspect-square" />
                                                                            ) : (
                                                                                <FaChevronDown size={25} className="object-contain text-neutral-500 shrink-0 self-stretch my-auto w-6 aspect-square" />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    {isOpen && (
                                                                        <>
                                                                            <div className="mt-4 text-base leading-7 text-neutral-600 max-md:max-w-full">
                                                                                {workHistory.job_description}
                                                                            </div>
                                                                            <div className="mt-4 text-base font-bold leading-none text-neutral-600 max-md:max-w-full">
                                                                                Projects
                                                                            </div>
                                                                            <div className="flex flex-wrap gap-2.5 items-start mt-4 w-full max-md:max-w-full">
                                                                                {workHistory.projects?.map((project, index) => (
                                                                                    <div className="flex flex-col w-80 min-w-[320px]">
                                                                                        <img
                                                                                            loading="lazy"
                                                                                            alt=""
                                                                                            srcSet={project.thumbnail_path}
                                                                                            className="object-contain w-full aspect-[1.49]"
                                                                                        />
                                                                                        <div className="flex overflow-hidden flex-col mt-2 w-full">
                                                                                            <div className="text-base font-bold leading-none text-neutral-800">
                                                                                                {project.title}
                                                                                            </div>
                                                                                            <div className="text-xs text-neutral-800">
                                                                                                {project.category}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <hr className="object-contain mt-7 w-full max-md:mt-5" />
                                                            </>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}


                                        {/* Skills */}
                                        {(selectedTab === "skills-experties" || selectedTab === "overview") && (
                                            <div className="flex flex-col p-4 w-full bg-white rounded-xl">
                                                <div className="text-3xl font-semibold text-neutral-800 max-md:max-w-full">
                                                    Skills & Expertise
                                                </div>
                                                <div className="flex flex-wrap gap-4 items-start mt-4 w-full text-base text-neutral-600 max-md:max-w-full">
                                                    {profileInfo.skills?.map((skill, index) => (
                                                        <div className="gap-2 self-stretch px-4 py-2 bg-violet-50 rounded-[100px]" key={index}>
                                                            {skill.name}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}


                                        {/* Education */}
                                        {(selectedTab === "education" || selectedTab === "overview") && (
                                            <div className="flex flex-col p-4 w-full bg-white rounded-xl">
                                                <div className="text-3xl font-semibold leading-none text-neutral-800 max-md:max-w-full">
                                                    Educational & Certification
                                                </div>
                                                {profileInfo.educations?.map((education, index) => (
                                                    <div className="flex flex-wrap gap-4 leading-tight items-center mt-4 w-full max-md:max-w-full" key={index}>
                                                        <img
                                                            loading="lazy"
                                                            alt=""
                                                            srcSet={education.thumbnail_path}
                                                            className="object-contain shrink-0 aspect-square w-[77px]"
                                                        />
                                                        <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                                            <div className="font-bold text-neutral-600 max-md:max-w-full">
                                                                {education.name}
                                                            </div>
                                                            <div className="mt-1 max-md:max-w-full">
                                                                {education.degree_or_course}
                                                            </div>
                                                            <div className="mt-1 max-md:max-w-full">{`${formatYear(education.date_started)} - ${formatYear(education.date_graduated)}`}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}


                                        {/* Awards */}
                                        {(selectedTab === "awards" || selectedTab === "overview") && (
                                            <div className="flex flex-col p-4 w-full bg-white rounded-xl">
                                                <div className="text-3xl font-semibold leading-none text-neutral-800 max-md:max-w-full">
                                                    Honors & awards
                                                </div>
                                                {profileInfo.awards?.map((award, index) => (
                                                    <div className="flex flex-col mt-4 w-full max-md:max-w-full">
                                                        <div className="flex flex-wrap gap-4 items-center w-full leading-tight max-md:max-w-full">
                                                            <img
                                                                loading="lazy"
                                                                alt=""
                                                                srcSet={award.thumbnail_path}
                                                                className="object-contain shrink-0 aspect-[1.02] w-[55px]"
                                                            />
                                                            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                                                <div className="font-bold text-neutral-600 max-md:max-w-full">
                                                                    {award.title}
                                                                </div>
                                                                <div className="text-neutral-600 mt-1 max-md:max-w-full">
                                                                    Issued by {award.issued_by} · {formatDate(award.issued_date)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mt-2 leading-7 text-neutral-600 max-md:max-w-full">
                                                            {award.description}
                                                            <br />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-[350px] max-[1024px]:w-full mt-20 rounded-lg max-md:mt-10 max-md:mr-2.5">
                                <div className="border border-gray-200 border-solid">
                                    <div className="flex flex-col max-[1024px]:w-full max-[1024px]:flex-row max-[425px]:flex-col max-[425px]:items-start gap-4 justify-center max-[1024px]:justify-evenly p-4 border border-gray-200">
                                        <div className="flex flex-col max-[1024px]:items-center max-[425px]:items-start justify-center">
                                            <div className="flex overflow-hidden gap-2 items-center p-1 text-lg font-semibold leading-tight text-blue-900 whitespace-nowrap">
                                                <AiOutlineClockCircle size={23} />
                                                <div className="self-stretch my-auto">Availability</div>
                                            </div>
                                            <div className="mt-2 ml-1 text-base leading-loose text-neutral-600">
                                                {profileInfo.availability}
                                            </div>
                                        </div>
                                        <hr className="object-contain w-full aspect-[250] hidden max-[1024px]:hidden max-[425px]:block" />
                                        <div className="flex flex-col max-[1024px]:items-center max-[425px]:items-start justify-center">
                                            <div className="flex overflow-hidden flex-wrap gap-2 items-center px-0.5 py-px text-lg font-semibold leading-tight text-blue-900">
                                                <HiOutlineBriefcase size={23} />
                                                <div className="self-stretch my-auto">Work Arrangement</div>
                                            </div>
                                            <div className="mt-2 ml-1 text-base leading-loose text-neutral-600">
                                                {profileInfo.work_setup_preferences}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <ProfileSideBar profileInfo={profileInfo} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ProfileMain;