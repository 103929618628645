import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { encryption } from "../../encryption/encryption";
import { setIsLoading } from "../../actions";
import { useDispatch } from "react-redux";
import { getProfessions } from "../apiCall";


const useRegistrationForm = () => {
  const [applySuccess, setApplySuccess] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedProfession, setSelectedProfession] = useState("");
  const [languages, setLanguages] = useState([]);
  const [interviewLanguage, setInterviewLanguage] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [registrationPhoneNumber, setRegistrationPhoneNumber] = useState("");
  const [errors, setErrors] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const uniqueLanguages = Array.from(
          new Set(
            response.data.flatMap((country) => 
              country.languages ? Object.values(country.languages) : []
            )
          )
        ).sort((a, b) => a.localeCompare(b)); // Sort languages alphabetically
  
        setLanguages(uniqueLanguages);
      })
      .catch((error) => {
        console.error("Error fetching countries: ", error);
      });

    // handleGetProfessions();
  }, []);

  // const handleGetProfessions = async () => {
  //   try {
  //     const data1 = {
  //       industry: "Software Development",
  //       params: `token=${process.env.REACT_APP_PROFESSON_TOKEN}`
  //     };
  
  //     const data2 = {
  //       industry: "Hardware Development",
  //       params: `token=${process.env.REACT_APP_PROFESSON_TOKEN}`
  //     };
  
  //     const [response1, response2] = await Promise.all([
  //       getProfessions(data1),
  //       getProfessions(data2)
  //     ]);
  
  //     if (response1.status === 200 && response2.status === 200) {
  //       setSoftwareProfessions(response1.data);
  //       setHardwareProfessions(response2.data);
  //     }
  //   }
  //   catch(error){
  //     console.log(error);
  //   }
  // }

  const handleApply = () => {
    const formData = {
      fullname: fullName,
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      phone_number: `+${registrationPhoneNumber}`,
      is_part_of_company: 0,
      position: selectedOption,
      profession: selectedProfession,
      industry: selectedOption === "Software Developer" ? "Technology and Software Development":"Technology Hardware Services",
      user_type_tag: "Developer",
      registered_through: "devreg",
      interview_language: interviewLanguage,
    };
    submitRegistrationForm(formData);
  };

  const submitRegistrationForm = async (formData) => {
    dispatch(setIsLoading(true));
    try{

      let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/api/register`,
          headers: {
              'X-Requested-With': 'XMLHttpRequest', 
          },
          data : formData
      };

      await axios.request(config)
      .then((response) => {
          console.log(response.data);
            if(response.status === 201){
               setErrors("");
              // console.log('Encrypted ID: '+encryption(response.data.user.id))
              // console.log('Encrypted Token: '+encryption(response.data.token))
              // localStorage.setItem("id", encryption(response.data.user.id));
              // localStorage.setItem("token", encryption(response.data.token));
              localStorage.setItem("id", response.data.user.id);
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("fullname", response.data.user.fullname);
              localStorage.setItem("email", response.data.user.email);
              localStorage.setItem("phone_number", response.data.user.phone_number);
              localStorage.setItem("user", JSON.stringify(response.data.user));
              setApplySuccess(true);
              navigate(`/registration-success?sn=${response.data.user.serial_number}`);
              // navigate(`/personal-information?sn=${response.data.user.serial_number}`);
              // navigate("/verification");
            }
      })
      .catch((error) => {
        // Set the error message from response
        setErrors(error.response.data.message);
      });
    }
    catch(error){
      console.log(error);
      setErrors(error.response.data.message);
    }
    finally{
      dispatch(setIsLoading(false));
    }
  };

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSelectChangeProfession = (val) => {
    setSelectedProfession(val);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
      setIsLengthValid(value.length >= 8);
      setHasUpperCase(/[A-Z]/.test(value));
      setHasLowerCase(/[a-z]/.test(value));
      setHasNumber(/\d/.test(value));
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':"|,.<>?]+/.test(value));
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
      setPasswordsMatch(value === password);
    }
  };

  const validateEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(newEmail));
  };

  const handlePhoneNumberChange = (phoneNumber) => {
    setRegistrationPhoneNumber(phoneNumber);
  };

  return {
    applySuccess,
    setCheckboxChecked,
    checkboxChecked,
    selectedOption,
    selectedProfession,
    languages,
    interviewLanguage,
    fullName,
    password,
    confirmPassword,
    isLengthValid,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    passwordsMatch,
    email,
    isValidEmail,
    registrationPhoneNumber,
    setFullName,
    setPassword,
    setConfirmPassword,
    setIsLengthValid,
    setHasUpperCase,
    setHasLowerCase,
    setHasNumber,
    setHasSpecialChar,
    setPasswordsMatch,
    setEmail,
    setIsValidEmail,
    setRegistrationPhoneNumber,
    handleApply,
    handleSelectChange,
    handleSelectChangeProfession,
    setLanguages,
    setInterviewLanguage,
    handleChange,
    validateEmail,
    handlePhoneNumberChange,
    setApplySuccess,
    errors,
  };
};

export default useRegistrationForm;
